import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import SelectButton from '@shared/ui/buttons/SelectButton';
import { Banner, ContentDivider, StepFormContainer, StepText, StepTitle, WalletOption, } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { ShieldIcon } from '@shared/ui/sidebars/icons';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import usePortfolioCreate from '../usePortfolioCreate';
import { selectWalletValidationSchema } from './validation';
const SelectWallet = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { handleExchangeConnect, goToNextStep, handleClose, sidebarFormValues, goToPrevStep, displayBackBtn, wallets, } = usePortfolioCreate();
    const { watch, handleSubmit, setValue, formState: { errors }, trigger, } = useForm({
        defaultValues: sidebarFormValues,
        resolver: yupResolver(selectWalletValidationSchema(t, wallets)),
    });
    console.log({ errors });
    const walletId = watch('walletId');
    const isSubmitBtnDisabled = (walletId === null || walletId === void 0 ? void 0 : walletId.length) === 0;
    const walletError = errors.walletId &&
        errors.walletId.message ? errors.walletId.message : undefined;
    const handleWalletSelect = (option) => {
        if (typeof option === 'string') {
            setValue('walletId', option);
            trigger('walletId');
        }
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, title: displayBackBtn ? (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.connect_exchange.back_btn') })) :
            undefined, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepFormContainer, { onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepTitle, { alignSelf: 'start', margin: `0px 0px ${theme.spacing_sizes.xs * 5}px`, children: t('portfolio.create_portfolio.steps.select_wallet.title', { ns: 'common' }) }), _jsx(StepText, { stepNumber: t('portfolio.create_portfolio.steps.select_wallet.step_number', { ns: 'common' }), text: t('portfolio.create_portfolio.steps.select_wallet.step_text', { ns: 'common' }) }), _jsx(SelectButton, { options: wallets.map((wallet => ({
                            value: wallet.id,
                            element: (_jsx(WalletOption, { walletType: wallet.type, walletName: wallet.exchangeParentName, amount: parseFloat(wallet.totalAssetsUsd), icon: _jsx(ExchangeIcon, {}) })),
                        }))), error: walletError, fullWidth: true, onChange: handleWalletSelect, value: walletId }), _jsx(ContentDivider, { text: t('portfolio.create_portfolio.steps.select_wallet.divider_text', { ns: 'common' }) }), _jsx(Button, { type: 'button', onClick: handleExchangeConnect, startIcon: PlusIcon, size: 'large', variant: 'tinted', fullWidth: true, children: t('portfolio.create_portfolio.steps.select_wallet.connect_exchange_btn', { ns: 'common' }) })] })), contentJustify: 'start', contentFlex: 1, buttons: (_jsxs(_Fragment, { children: [_jsx(Banner, { icon: _jsx(ShieldIcon, {}), text: t('portfolio.create_portfolio.safety_banner_text', { ns: 'common' }) }), _jsx(Button, { size: 'large', type: 'submit', fullWidth: true, disabled: isSubmitBtnDisabled, children: t('portfolio.create_portfolio.steps.select_wallet.continue_btn', { ns: 'common' }) })] })), buttonsGap: theme.spacing_sizes.l }) }));
};
export default SelectWallet;
