import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { Banner, ContentDivider, StepFormContainer, StepText, StepTitle, } from '@shared/ui/sidebars/shared';
import SelectButton from '@shared/ui/buttons/SelectButton';
import Button from '@shared/ui/buttons/Button';
import { BinanceNameIcon, BitmexIcon, CoinsferaIcon, IconContainer, ShieldIcon, } from '@shared/ui/sidebars/icons';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import usePortfolioCreate from '../usePortfolioCreate';
const SelectExchange = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { handleClose, sidebarFormValues, goToNextStep, goToPrevStep, displayBackBtn, } = usePortfolioCreate();
    const { handleSubmit, setValue, watch } = useForm({
        defaultValues: sidebarFormValues,
    });
    const exchange = watch('exchange');
    const handleExchangeSelect = (option) => {
        if (typeof option === 'string') {
            setValue('exchange', option);
        }
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, title: displayBackBtn ? (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.connect_exchange.back_btn') })) : null, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepFormContainer, { title: _jsx(StepTitle, { children: t('overview.connect_exchange.select_exchange.title') }), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { text: t('overview.connect_exchange.select_exchange.text'), stepNumber: t('overview.connect_exchange.select_exchange.step') }), _jsx(SelectButton, { options: [{
                                value: 'binance',
                                element: (_jsx(IconContainer, { icon: _jsx(BinanceNameIcon, {}) })),
                            }], onChange: handleExchangeSelect, value: exchange || undefined, fullWidth: true }), _jsx(ContentDivider, { text: t('portfolio.create_portfolio.steps.select_exchange.unavailble_options_divider', { ns: 'common' }) }), _jsx(SelectButton, { options: [
                            {
                                value: 'bitmex',
                                element: (_jsx(IconContainer, { icon: _jsx(BitmexIcon, {}) })),
                                disabled: true,
                            },
                            {
                                value: 'coinsfera',
                                element: (_jsx(IconContainer, { icon: _jsx(CoinsferaIcon, {}) })),
                                disabled: true,
                            },
                        ], fullWidth: true })] })), contentJustify: 'start', contentFlex: 1, containerGap: theme.spacing_sizes.s * 10, buttons: (_jsxs(_Fragment, { children: [_jsx(Banner, { icon: _jsx(ShieldIcon, {}), text: t('portfolio.create_portfolio.safety_banner_text', { ns: 'common' }) }), _jsx(Button, { size: 'large', type: 'submit', disabled: exchange === null, fullWidth: true, children: t('overview.connect_exchange.select_exchange.continue_btn') })] })), buttonsGap: theme.spacing_sizes.l }) }));
};
export default SelectExchange;
