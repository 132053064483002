export const WALLET_MOCKS = [
    {
        walletName: 'First Binance',
        walletType: 'SPOT',
        id: '132',
        amount: 123.09,
        currency: '$',
        exchangeType: 'binance',
    },
    {
        walletName: 'First Binance',
        walletType: 'MARGIN',
        id: '2142',
        amount: 15.09,
        currency: '$',
        exchangeType: 'bitmex',
    },
    {
        walletName: 'First Binance',
        walletType: 'MARGIN',
        id: '31414',
        amount: 15.09,
        currency: '$',
        exchangeType: 'fidelity',
    },
];
export const GROUPED_MOCKS = [
    {
        key: 'Artur',
        label: 'Artur',
        items: [
            {
                walletName: 'First Binance',
                walletType: 'SPOT',
                id: '132',
                amount: 123.09,
                currency: '$',
                exchangeType: 'BINANCE',
            },
            {
                walletName: 'First Binance',
                walletType: 'MARGIN',
                id: '2142',
                amount: 15.09,
                currency: '$',
                exchangeType: 'BITMEX',
            },
            {
                walletName: 'First Binance',
                walletType: 'MARGIN',
                id: '31414',
                amount: 15.09,
                currency: '$',
                exchangeType: 'FIDELITY',
            },
        ],
    },
];
